<template>
  <div>
    <b-row>
      <b-col md="3"> 
        <profil_kelompok :detailKelompok = detailKelompok ></profil_kelompok>
      </b-col>
      <b-col md="9">
        <div class="row">
          <div class="col-lg-6">

            <div class="form-group ">
              <input type="text" id="payment-card-number" class="form-control" v-model="cari"
                placeholder="search username or nema ">
            </div>
          </div>
          <div class="col-lg-2">
          </div>

          <div class="col-lg-4">
            <b-button class="btn-primary mb-1" block  
              v-b-modal.modal-no-backdrop variant="outline-primary">
              <i class="fa fa-plus"></i>
              Tambah Anggota
            </b-button>
          </div>
        </div>
        <card-user @get_user=get_user :rs_user=rs_user :id_kelompok=id_kelompok :rs_kelompok = rs_kelompok ></card-user>
      </b-col>
    </b-row>

    <FormAdd @get_user=get_user :id_kelompok = id_kelompok></FormAdd>




  </div>
</template>

<script>
import profil_kelompok from './profilKelompok';
  import {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,BAvatar
  } from 'bootstrap-vue'

  import {
    mapMutations,
    mapState
  } from 'vuex'
  import Base from '@/config/Mixins_base';
  import axios from '../../../config/Axios';
  import Ripple from 'vue-ripple-directive'
  import {
    BPagination,
    BModal,
    BButton,
    VBModal,


  } from 'bootstrap-vue'
  import CardUser from './component/CardUser.vue'
  import FormAdd from './component/FormAdd.vue'
  export default {
    data() {
      return {
        rs_user: [],
        cari: '',
        offset : 0,
        id_kelompok :  this.$route.params.id_kelompok,
        detailKelompok : {},
        rs_kelompok : []
      }
    },
    mixins: [Base],

    directives: {
      'b-modal': VBModal,
      Ripple,
    },
    computed: {
      ...mapState({
        Rsuser: state => state.aclUser.Rsuser,
      })
    },
    watch: {
      cari(newData, old) {
        if (newData != NaN) {
          this.get_user("1", this.cari);
        }
      }
    },
    components: {
      CardUser,
      FormAdd,
      BPagination,
      BModal,
      BButton,
      VBModal,
      BRow,
      BCol,
      BCard,
      BCardText,
      BCardTitle,BAvatar,profil_kelompok,

    },
    mounted() { 
      this.cek_token();
      this.id_kelompok = this.$route.params.id_kelompok; 
      this.get_edit();
      this.get_user();
      this.kelompok_list();
    },
    methods: {
      ...mapMutations({
        SetRsUser: 'SetRsUser',
      }),

     

      async get_edit() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/kelompok/get_edit',
            data: {
              id_kelompok: self.id_kelompok
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.detailKelompok = response.data.result; 
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async kelompok_list() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/anggota/get_kelompok',
            data: {
              id_kelompok: self.id_kelompok
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rs_kelompok = response.data.result; 
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },



      async get_user( ) {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/anggota/load_data',
            data: {
              offset: self.offset,
              cari: self.cari,
              id_kelompok : self.id_kelompok
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.SetRsUser(response.data.result);
            self.rs_user = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }
  }
</script>

<style>

</style>