<template>
    <div>
        <b-card class="bg-primary-2" text-variant="white">
            <b-card-title class="text-white text-center">
                <feather-icon icon="GridIcon" size="70" />
                <h4 class="text-white mt-1">{{ detailKelompok.nama_kelompok }}</h4>
                <h6 class="text-white ">{{  detailKelompok.id_bidang.nama_bidang}}</h6>
            </b-card-title>
            <!-- <b-card-text> 
            Some quick example text to build on the card title and make up.
          </b-card-text> -->
            <div>
                <span>
                    <b>Kordinator</b><br>
                    <b-avatar size="20px" rounded="">
                        <feather-icon icon="UserIcon" size="15" />

                    </b-avatar> {{ detailKelompok.nm_kordinator }}<br>
                    <b-avatar size="20px" rounded="">
                        <feather-icon icon="PhoneIcon" size="15" />

                    </b-avatar> {{ detailKelompok.no_hp }}
                </span>
            </div>
            <p class="mt-1"><b>Keterangan</b><br>{{ detailKelompok.keterangan }}</p>
        </b-card>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BCardText,
        BCardTitle,
        BAvatar
    } from 'bootstrap-vue'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BCardText,
            BCardTitle,
            BAvatar
        },
        props: {
            detailKelompok: {}
        }
    }
</script>

<style>
.bg-primary-2 {
    background-color: #062d5fc2 !important;
}
</style>