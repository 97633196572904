<template>
  <div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12" v-for="user in  Rsuser.rs" v-bind:key="user.user_key">
        <div class="card card-apply-job" style="height: 270px;">
          <div class="card-body" style="height: 250px;">
            <div class="d-flex justify-content-between align-items-center mb-1" height="500px">
              <div class="media">
                <div class="avatar mr-1">
                  <img :src="user.foto" width="42" height="42">
                </div>
                <div class="media-body">
                  <h6 class="mb-0"><small>username</small><br>{{user.username}}
                    <small class="text-danger" v-if="user.status != '1'"> <i class="fa fa-warning "></i> </small>
                    <small class="text-info" v-if="user.status == '1'"> <i class="fa fa-check "></i> </small> </h6>

                </div>
              </div>
            </div>
            <h6 class="apply-job-title">{{user.nm_lengkap}} </h6>
            <span class="apply-job-title">NIK : {{user.nik}} <br>Jabatan : {{user.nama_jabatan}} </span>
            <small><br> {{user.email}}</small><br>
            <small>{{user.no_hp}}</small>

          </div>
          <b-row>
            <b-col md="11" class="ml-1">
              <b-button v-b-modal.Edit-user @click="userEdit(user.anggota_id)" block variant="outline-primary"
                class="mb-1">
                <feather-icon icon="EditIcon" size="15" /> Edit Anggota
              </b-button>
            </b-col>

          </b-row>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <i class="fa fa-list"></i> Total Row {{ Rsuser.lenght}}
      </div>
      <div class="col-lg-6">
        <b-pagination v-model="currentPage" :total-rows=" Rsuser.lenght" per-page='3' base-url="#" :align="'right'"
          @click="pageNext(currentPage)" />
      </div>
    </div>


    <EditUSer @get_user=loadPage :userData=userData :id_kelompok=id_kelompok :rs_kelompok=rs_kelompok></EditUSer>
    <PasswordUser :nm_lengkap=nm_lengkap :user_key=user_key></PasswordUser>
  </div>
</template>

<script>
  import axios from '@/config/Axios'
  import EditUSer from './EditUser';
  import Ripple from 'vue-ripple-directive'
  import {
    mapMutations,
    mapState
  } from 'vuex'

  import PasswordUser from './PasswordUser.vue'
  import Base from '@/config/Mixins_base';
  import {
    BPagination,
    BModal,
    BButton,
    VBModal,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol
  } from 'bootstrap-vue'
  export default {
    data() {
      return {
        selected: 'default',
        currentPage: 1,
        rows: 0,
        user_key: "",
        userData: {},
        nm_lengkap: "",
        offset: 0
      }
    },
    emits: ["get_user"],
    mixins: [Base],
    computed: {
      ...mapState({
        Rsuser: state => state.aclUser.Rsuser,
      })
    },
    props: {
      rs_user: {},
      id_kelompok: {},
      rs_kelompok: {}
    },
    components: {
      BDropdown,
      BDropdownItem,
      BPagination,
      BModal,
      BButton,
      VBModal,
      EditUSer,
      PasswordUser,
      BRow,
      BCol
    },
    mounted() {
      this.rows = this.Rsuser.lenght;
    },
    watch: {
      currentPage(newcurrentPage, oldcurrentPage) {
        if (newcurrentPage) {
          this.loadPage();
        }
      }
    },

    methods: {
      ...mapMutations({
        SetRsUser: 'SetRsUser',
      }),
      get_user() {
        self.$emit("get_user", "");
      },

      userEdit(key) {
        const self = this;
        axios({
            method: 'PUT',
            url: '/api/anggota/get_edit',
            data: {
              anggota_id: key
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.userData = response.data.result

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      updatePassword(userkey, nmlengkap) {
        this.user_key = userkey;
        this.nm_lengkap = nmlengkap;
      },

      enableDisable(anggota_id) {
        this.$emit('enableDisableUser', anggota_id)
      },

      pageNext(val) {
        this.offset = val;
        this.loadPage();
      },
      async loadPage() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/anggota/load_data',
            data: {
              cari: "",
              offset: self.currentPage,
              id_kelompok: self.id_kelompok
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.SetRsUser(response.data.result);
            self.rs_user = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

    },

    directives: {
      'b-modal': VBModal,
      Ripple,
    },
  }
</script>

<style>

</style>