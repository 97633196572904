var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center"},[_c('b-modal',{attrs:{"id":"Edit-user","hide-footer":"","no-close-on-backdrop":"","content-class":"shadow","title":"Edit Data Anggota","ok-title":"Accept"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Kelompok")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Kelompok","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"rules":"required","state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nama_kelompok","options":_vm.rs_kelompok},model:{value:(_vm.user_data.id_kelompok),callback:function ($$v) {_vm.$set(_vm.user_data, "id_kelompok", $$v)},expression:"user_data.id_kelompok"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Nama Lengkap")]),_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nama Lengkap"},model:{value:(_vm.user_data.nm_lengkap),callback:function ($$v) {_vm.$set(_vm.user_data, "nm_lengkap", $$v)},expression:"user_data.nm_lengkap"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("NIK")]),_c('validation-provider',{attrs:{"name":"NIK","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"NIK"},model:{value:(_vm.user_data.nik),callback:function ($$v) {_vm.$set(_vm.user_data, "nik", $$v)},expression:"user_data.nik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Email"},model:{value:(_vm.user_data.email),callback:function ($$v) {_vm.$set(_vm.user_data, "email", $$v)},expression:"user_data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("No Handphone")]),_c('validation-provider',{attrs:{"name":"NoHP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"No HP / WA "},model:{value:(_vm.user_data.no_hp),callback:function ($$v) {_vm.$set(_vm.user_data, "no_hp", $$v)},expression:"user_data.no_hp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Jabatan")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Jabatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"rules":"required","state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nama_jabatan","options":_vm.levelList},model:{value:(_vm.user_data.id_jabatan),callback:function ($$v) {_vm.$set(_vm.user_data, "id_jabatan", $$v)},expression:"user_data.id_jabatan"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v("Status")]),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-radio',{attrs:{"name":"some-radios","value":"1"},model:{value:(_vm.user_data.status),callback:function ($$v) {_vm.$set(_vm.user_data, "status", $$v)},expression:"user_data.status"}},[_vm._v(" Aktif ")])],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('b-form-radio',{attrs:{"name":"some-radios","value":"0"},model:{value:(_vm.user_data.status),callback:function ($$v) {_vm.$set(_vm.user_data, "status", $$v)},expression:"user_data.status"}},[_vm._v(" Non Aktif ")])],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Keterangan ")]),_c('validation-provider',{attrs:{"name":"Keterangan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Keterangan"},model:{value:(_vm.user_data.ket),callback:function ($$v) {_vm.$set(_vm.user_data, "ket", $$v)},expression:"user_data.ket"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('hr'),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mb-3 mt-1",attrs:{"variant":"outline-primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.actSave($event)}}},[_c('i',{staticClass:"fa fa-save mr-1"}),_vm._v(" Update ")])],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mb-3 mt-1",attrs:{"variant":"outline-warning","block":""},on:{"click":function($event){return _vm.$bvModal.hide('Edit-user')}}},[_c('i',{staticClass:"fa fa-close mr-1"}),_vm._v(" Keluar")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }