<template>
  <div class="center">
    <b-modal id="Password-user" hide-footer no-close-on-backdrop content-class="shadow" title="Password User"
      ok-title="Accept">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label>Nama Lengkap</label>
                <validation-provider #default="{ errors }" name="Nama Lengkap">
                  <b-form-input v-model="nm_lengkap" disabled='' placeholder="Nama Lengkap" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>Password</label>
                <validation-provider #default="{ errors }" name="password" rules="required">
                  <b-form-input v-model="password" :state="errors.length > 0 ? false:null" type="password"
                    placeholder="password" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <hr>
            <b-col cols="6">
              <b-button class="mb-3 mt-1" variant="outline-primary" block type="submit" @click.prevent="validationForm">
                <i class="fa fa-save mr-1"></i>Simpan
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button class="mb-3 mt-1" variant="outline-warning" block @click="$bvModal.hide('Password-user')">
                <i class="fa fa-close mr-1"></i> Keluar</b-button>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>



    </b-modal>
  </div>
</template>
<script>
  import axios from '@/config/Axios';
  import Base from '@/config/Mixins_base';

  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
  } from '@validations'
  import {
    BModal,
    BButton,
    VBModal,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BCardText,

  } from 'bootstrap-vue'
  import vSelect from 'vue-select'

  export default {
    props: {
      user_key: {},
      nm_lengkap: {}
    },
    data: () => ({
      required,
      password: ""


    }),
    mixins: [Base],
    components: {
      BModal,
      BButton,
      VBModal,
      ValidationProvider,
      ValidationObserver,
      BFormInput,
      BFormGroup,
      BForm,
      BRow,
      BCol,
      BCardText,
      vSelect,
    },
    methods: {
      validationForm() {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$root.$emit('bv::hide::modal', 'Password-user');
            const self = this;
            axios({
                method: 'POST',
                url: '/api/acl/user/update_password',
                data: {
                  user_key: self.user_key,
                  password: self.password
                },
                headers: {
                  'Authorization': self.isAuthenticated
                }
              })
              .then(function (response) {
                //title, text,variant 
                self.notification('primary', "Ok..", response.data.message); 
                self.$emit("get_user", "");

              }).catch(err => {
                self.pesan = err.message;
                self.notification('warning', "Error", err.message);
              });


          }
        })
      },
    },

  }
</script>