  <template>
    <div class="center">
      <b-modal id="modal-no-backdrop" hide-footer no-close-on-backdrop content-class="shadow" title="Add User"
        ok-title="Accept">
        <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label>Username</label>
                <validation-provider #default="{ errors }" name="Username" rules="required">
                  <b-form-input v-model="user_data.username" :state="errors.length > 0 ? false:null"
                    placeholder="Username" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>Nama Lengkap</label>
                <validation-provider #default="{ errors }" name="Nama Lengkap" rules="required">
                  <b-form-input v-model="user_data.nm_lengkap" :state="errors.length > 0 ? false:null"
                    placeholder="Nama Lengkap" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>NIK</label>
                <validation-provider #default="{ errors }" name="NIK" rules="required">
                  <b-form-input v-model="user_data.nik" :state="errors.length > 0 ? false:null" placeholder="NIK" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>Email</label>
                <validation-provider #default="{ errors }" name="Email"  >
                  <b-form-input v-model="user_data.email" :state="errors.length > 0 ? false:null" type="email"
                    placeholder="Email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>No Handphone</label>
                <validation-provider #default="{ errors }" name="NoHP" rules="required">
                  <b-form-input v-model="user_data.no_hp" :state="errors.length > 0 ? false:null" type="text"
                    placeholder="No HP / WA " />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <label>Jabatan</label>
              <b-form-group>
                <validation-provider #default="{ errors }" name="Jabatan" rules="required">
                  <v-select rules="required" :state="errors.length > 0 ? false:null" v-model="user_data.id_jabatan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_jabatan" :options="levelList" />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <label>Status</label>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <b-form-radio v-model="user_data.status" name="some-radios" value="1">
                      Aktif
                    </b-form-radio>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <b-form-radio v-model="user_data.status" name="some-radios" value="0">
                      Non Aktif
                    </b-form-radio>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <label>Keterangan </label>
                <validation-provider #default="{ errors }" name="Keterangan" rules="required">
                  <b-form-textarea v-model="user_data.ket" :state="errors.length > 0 ? false:null"
                    placeholder="Keterangan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <hr>
            <b-col cols="6">
              <b-button class="mb-3 mt-1" variant="outline-primary" block type="submit" @click.prevent="actSave">
                <i class="fa fa-save mr-1"></i> Simpan
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button class="mb-3 mt-1" variant="outline-warning" block @click="$bvModal.hide('Edit-user')">
                <i class="fa fa-close mr-1"></i> Keluar</b-button>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
      </b-modal>
    </div>
  </template>
  <script>
    import axios from '@/config/Axios';
    import Base from '@/config/Mixins_base';
    import {
      ValidationProvider,
      ValidationObserver
    } from 'vee-validate'
    import {
      required,
      email
    } from '@validations'
    import {
      BModal,
      BButton,
      VBModal, 
      BFormInput,
      BFormGroup,
      BForm,
      BRow,
      BCol,
      BCardText,
    BFormRadio,
    BFormTextarea

    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
      props: {
        active: {},
        id_kelompok : {}
      },
      mixins: [Base],
      data: () => ({
        required, email, 
        user_data : {},
        levelList: []

      }),
      emits: ["get_user"],
      components: {
        BModal,
        BButton,
        VBModal, 
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BCardText,
        vSelect,
    BFormRadio,
    BFormTextarea
      },
      mounted() {
        this.getLevel();
      },
      methods: {
        async getLevel() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/anggota/jabatan',
            data: {

            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.levelList = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
        async AddUser() {
          const self = this;
          await axios({
              method: 'POST',
              url: '/api/anggota/insert ',
              data: {  
              id_kelompok : self.id_kelompok,
              id_jabatan: self.user_data.id_jabatan.id_jabatan,
              nik: self.user_data.nik,
              status: self.user_data.status,
              nm_lengkap: self.user_data.nm_lengkap,
              no_hp: self.user_data.no_hp,
              email: self.user_data.email,
              ket: self.user_data.ket,
              username : self.user_data.username
              },
              headers: {
                'Authorization': self.isAuthenticated
              }
            })
            .then(function (response) {
              self.notification('warning', "info", response.data.message);
              self.$emit("get_user", "");
              self.user_data = {}

            }).catch(err => {
              self.pesan = err.message;
              self.notification('warning', "Error", err.message);
            });
        },
        actSave() {
          this.$refs.simpleRules.validate().then(success => {
            if (success) {
              this.AddUser();
              this.$root.$emit('bv::hide::modal', 'modal-no-backdrop');
            }
          })
        },
      },

    }
  </script>